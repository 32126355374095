import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "MapPage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_AppMap = _resolveComponent("AppMap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (_ctx.$route.name !== '404')
          ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })),
    _createVNode(_component_AppMap)
  ]))
}