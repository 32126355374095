
import Application from '@/models/Application';
import { defineAsyncComponent, defineComponent } from "vue";

export default defineComponent({
  name: "AppVenues",
  components: {
    AppVenue: defineAsyncComponent(() => import(/* webpackPrefetch: true */ '@/components/AppVenue.vue')),
    VenueDetail: defineAsyncComponent(() => import(/* webpackPrefetch: true */ '@/components/VenueDetail.vue')),
  },
  setup() {
    return {
      venues: Application.venues,
      detailVenue: Application.detailVenue,
    };
  },
});
