
import Application from '@/models/Application';
import Venue from '@/models/Venue';
import { v4 as uuid } from 'uuid';
import { defineAsyncComponent, defineComponent, onBeforeMount, onMounted, Ref, ref } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    AppLoader: defineAsyncComponent(() => import(/* webpackPrefetch: true */ '@/components/AppLoader.vue')),
    AppHeader: defineAsyncComponent(() => import(/* webpackPrefetch: true */ '@/components/AppHeader.vue')),
    AppTutorial: defineAsyncComponent(() => import(/* webpackPrefetch: true */ '@/components/AppTutorial.vue')),
    RateVenueModal: defineAsyncComponent(() => import(/* webpackPrefetch: true */ '@/components/RateVenueModal.vue')),
    LanguageSelector: defineAsyncComponent(() => import(/* webpackPrefetch: true */ '@/components/LanguageSelector.vue')),
    RouteModal: defineAsyncComponent(() => import(/* webpackPrefetch: true */ '@/components/RouteModal.vue')),
    AppMap: defineAsyncComponent(() => import(/* webpackPrefetch: true */ '@/components/AppMap.vue')),
  },

  setup() {
    const windowWidth = ref(window.innerWidth)
    let languageSelected = ref('uk');
    onBeforeMount(() => {
      if (!localStorage.getItem('guestUuid')) {
        localStorage.setItem('guestUuid', uuid());
      }
      languageSelected.value = localStorage.getItem('languageSelected') || '';
      Application.language.value = localStorage.getItem('languageSelected') || 'uk';
      if (!languageSelected.value) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    });

    onMounted(async () => {
      Application.showLoader();

      await Application.setVenues();

      Application.hideLoader();
    });

    function selectLanguage(lang: string) {
      localStorage.setItem('languageSelected', lang);
      languageSelected.value = lang;
      Application.setLanguage(lang);
    }

    return {
      windowWidth,
      languageSelected,
      selectLanguage,
      menuOpen: Application.menuOpen,
      tutorialOpen: Application.tutorialOpen,
      ratingVenue: Application.ratingVenue as Ref<Venue>,
      routeModalOpen: Application.routeModalOpen,
    }
  }
})
