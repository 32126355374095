export default {
  uk: {
    header: {
      sort_by_rating: 'Сортувати за рейтингом',
      instruction: 'Інструкція',
      instruction_content: 'Щоб прокласти марштур, додайте локацію, що зацікавила, до обраних.',
      search_by_map: 'Пошук по карті',
      search_by_list: 'Пошук по списку',
      developed_by: 'Розробка та підтримка',
      source_link_pbu: 'https://www.pbu2020.eu/ua',
      source_link_project: 'https://www.pbu2020.eu/ua/projects2020/232',
    },
    errors: {
      oops: 'От халепа...',
      message: {
        sorry_but: 'Вибачте, але такої сторінки',
        not_found: 'не існує.',
      },
      back_to: 'Повернутись на головну',
    },
    make_route: 'Прокласти маршрут',
    modals: {
      make_rate_content: 'Будь ласка, оцініть дану локацію, клацнувши на відповідну зірочку нижче.',
      make_rate: 'Оцінити локацію',
      thank_you: 'Дякуємо за відгук!'
    },
    buttons: {
      agree: 'Зрозуміло'
    },
    venues: {
      recommended: 'Рекомендовано',
      rating: 'Рейтинг',
      rate: 'Оцінити',
      change_rating: 'Змінити оцінку',
      show_on_map: 'Показати на карті',
      add_to_favourites: 'Додати в обрані',
      remove_from_favourites: 'Прибрати з обраних',
    },
  },
  en: {
    header: {
      sort_by_rating: 'Sort by rating',
      instruction: 'Tips',
      instruction_content: 'To get the route, add a location to your favorites',
      search_by_map: 'Search by map',
      search_by_list: 'Search by list',
      developed_by: 'Developed by',
      source_link_pbu: 'https://www.pbu2020.eu/en',
      source_link_project: 'https://www.pbu2020.eu/en/projects2020/146',
    },
    errors: {
      oops: 'Oh no...',
      message: {
        sorry_but: 'Sorry but this page',
        not_found: 'doesn\'t exist.',
      },
      back_to: 'Back to main',
    },
    make_route: 'Show routes',
    modals: {
      make_rate_content: 'Please rate the location by clicking on a relevant star below.',
      make_rate: 'Rate the location',
      thank_you: 'Thank you for the feedback!'
    },
    buttons: {
      agree: 'Clear'
    },
    venues: {
      recommended: 'Recommended',
      rating: 'Rating',
      rate: 'Rate',
      change_rating: 'Change rating',
      show_on_map: 'Show on map',
      add_to_favourites: 'Add to favorites',
      remove_from_favourites: 'Remove from favorites',
    },
  },
  de: {
    header: {
      sort_by_rating: 'Nach Bewertung sortieren',
      instruction: 'Tipps',
      instruction_content: 'Um die Route abzurufen, fügen Sie einen Standort zu Favoriten hinzu',
      search_by_map: 'Suche nach Karte',
      search_by_list: 'Suche nach Liste',
      developed_by: 'Developed by',
      source_link_pbu: 'https://www.pbu2020.eu/en',
      source_link_project: 'https://www.pbu2020.eu/en/projects2020/146',
    },
    errors: {
      oops: 'Oh doch',
      message: {
        sorry_but: 'Entschuldigung, aber diese Seite',
        not_found: 'existiert nicht',
      },
      back_to: 'Zurück zur Hauptseite',
    },
    make_route: 'Routen anzeigen',
    modals: {
      make_rate_content: 'Bitte bewerten Sie den Standort, indem Sie unten auf einen entsprechenden Stern klicken',
      make_rate: 'Bewerten Sie den Standort',
      thank_you: 'Danke für die Rückmeldung!'
    },
    buttons: {
      agree: 'Klar'
    },
    venues: {
      recommended: 'Empfohlen',
      rating: 'Bewertung',
      rate: 'Bewerten',
      change_rating: 'Bewertung ändern',
      show_on_map: 'Auf der Karte anzeigen',
      add_to_favourites: 'Zu den Favoriten hinzufügen',
      remove_from_favourites: 'Von Favoriten entfernen',
    },
  },
  pl: {
    header: {
      sort_by_rating: 'Sortuj według ocen',
      instruction: 'Wskazówki',
      instruction_content: 'Aby zobaczyć trasę, dodaj lokalizację do ulubionych',
      search_by_map: 'Szukaj na mapie',
      search_by_list: 'Szukaj na liście',
      developed_by: 'Developed by',
      source_link_pbu: 'https://www.pbu2020.eu/pl',
      source_link_project: 'https://www.pbu2020.eu/pl/projects2020/230',
    },
    errors: {
      oops: 'O, nie…',
      message: {
        sorry_but: 'Przepraszamy, ale ta strona',
        not_found: 'nie istnieje',
      },
      back_to: 'Powrót do strony głównej',
    },
    make_route: 'Pokaż trasy',
    modals: {
      make_rate_content: 'Oceń lokalizację, zaznaczając odpowiednią liczbę gwiazdek.',
      make_rate: 'Oceń lokalizację',
      thank_you: 'Dziękujemy za informację zwrotną!'
    },
    buttons: {
      agree: 'Jasne'
    },
    venues: {
      recommended: 'Polecane',
      rating: 'Ocena',
      rate: 'Oceń',
      change_rating: 'Zmień ocenę',
      show_on_map: 'Pokaż na mapie',
      add_to_favourites: 'Dodaj do ulubionych',
      remove_from_favourites: 'Usuń z ulubionych',
    },
  },
  ru: {
    header: {
      sort_by_rating: 'Сортировать по рейтингу',
      instruction: 'Подсказки',
      instruction_content: 'Чтобы проложить маршрут, добавьте интересующую локацию в избранные.',
      search_by_map: 'Поиск по карте',
      search_by_list: 'Поиск по списку',
      developed_by: 'Разработка и поддержка',
      source_link_pbu: ' https://www.pbu2020.eu/by',
      source_link_project: 'https://www.pbu2020.eu/by/projects2020/231',
    },
    errors: {
      oops: 'Только не это...',
      message: {
        sorry_but: 'Извините, но данная страница',
        not_found: 'не существует.',
      },
      back_to: 'Назад на главную',
    },
    make_route: 'Проложить маршрут',
    modals: {
      make_rate_content: 'Пожалуйста, оцените данную локацию, кликнув на звездочку, которая соответсвует вашей оценке.',
      make_rate: 'Оценить локацию',
      thank_you: 'Спасибо за оценку!'
    },
    buttons: {
      agree: 'Понятно'
    },
    venues: {
      recommended: 'Рекомендовано',
      rating: 'Рейтинг',
      rate: 'Оценить',
      change_rating: 'Изменить оценку',
      show_on_map: 'Показать на карте',
      add_to_favourites: 'В избранное',
      remove_from_favourites: 'Удалить из избранного',
    },
  },
}
