import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-366d591c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex h-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppLoader = _resolveComponent("AppLoader")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_RouteModal = _resolveComponent("RouteModal")!
  const _component_AppTutorial = _resolveComponent("AppTutorial")!
  const _component_RateVenueModal = _resolveComponent("RateVenueModal")!
  const _component_AppMap = _resolveComponent("AppMap")!
  const _component_language_selector = _resolveComponent("language-selector")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppLoader),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["flex-grow overflow-auto h-full", {
         'left-column': _ctx.$route.name !== '404',
         'sm:flex-grow-0': _ctx.$route.name !== '404',
         'flex-grow-0': _ctx.$route.name !== '404',
         'flex': _ctx.$route.name === '404'
        }])
      }, [
        (_openBlock(), _createBlock(_Suspense, null, {
          default: _withCtx(() => [
            (_ctx.$route.name !== '404')
              ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })),
        _createVNode(_component_router_view),
        (_ctx.routeModalOpen)
          ? (_openBlock(), _createBlock(_component_RouteModal, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.tutorialOpen)
          ? (_openBlock(), _createBlock(_component_AppTutorial, { key: 1 }))
          : _createCommentVNode("", true),
        (_openBlock(), _createBlock(_Suspense, null, {
          default: _withCtx(() => [
            (_ctx.ratingVenue)
              ? (_openBlock(), _createBlock(_component_RateVenueModal, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["hidden flex-grow", {
        'bg-opacity-60': _ctx.menuOpen,
        'sm:block': _ctx.$route.name !== '404',

      }])
      }, [
        (_ctx.windowWidth >= 640)
          ? (_openBlock(), _createBlock(_component_AppMap, { key: 0 }))
          : _createCommentVNode("", true)
      ], 2),
      (_openBlock(), _createBlock(_Suspense, null, {
        default: _withCtx(() => [
          (!_ctx.languageSelected)
            ? (_openBlock(), _createBlock(_component_language_selector, {
                key: 0,
                onSelect: _ctx.selectLanguage
              }, null, 8, ["onSelect"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    ])
  ], 64))
}