
import { defineAsyncComponent, defineComponent } from "vue";

export default defineComponent({
  name: "map-page",
  components: {
    AppMap: defineAsyncComponent(() => import(/* webpackPrefetch: true */ '@/components/AppMap.vue')),
    AppHeader: defineAsyncComponent(() => import(/* webpackPrefetch: true */ '@/components/AppHeader.vue')),
  },
});
